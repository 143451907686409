<template>
  <modal
    id="modalVinculaTemaExercicio"
    name="modalNameVinculaTemaExercicio"
    :height="'60%'"
    :width="'50%'"
    :draggable="true"
    :adaptive="true"
  >
    <div class="container position-relative h-100">
      <div class="d-flex pt-3 pb-2">
        <!-- <div class="id bg-42cc7e mr-2">
          <span class="color-fff f-w-700">{{ temaDados }}</span>
        </div> -->
      </div>
      <div class="row">
        <div class="col-md-12 w-100">
          <label class="typo__label"
            >Unidades/Instituições e suas respectivas Turmas</label
          >
          <multiselect
            v-model="turmasVincula"
            :options="options"
            :multiple="true"
            :max-height="200"
            group-values="turmas"
            group-label="unidade"
            :group-select="true"
            placeholder="Selecione as unidades e suas turmas"
            track-by="name"
            label="name"
            ><span
              slot="noResult"
              :searchable="false"
              @select="dispatchAction()"
              >Oops! nenhum elemento encontrado...</span
            ></multiselect
          >
        </div>
        <div class="btns-vincular position-absolute w-100">
          <div>
            <button
              :disabled="load"
              @click="hideModal('modalNameVinculaTemaExercicio')"
              class="btn-style-default bg-d42338 color-fff mr-3"
            >
              Cancelar
            </button>
            <button
              :disabled="load"
              class="btn-style-default bg-0e5daf color-fff"
              @click="vincular()"
            >
              <span v-if="!load">Vincular</span>
              <div v-else class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
const VUE_APP_CMS = "https://api.skaki.com.br/";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },
  // computed: {
  //   ...mapGetters(["getUnidades"]),
  // },
  props: ["temaDados"],
  data() {
    return {
      load: false,
      turmasVincula: [],
      options: [
        {
          unidade: null,
          turmas: [],
        },
      ],
    };
  },
  watch: {
    turmasVincula() {
      console.log(this.turmasVincula);
    },
    temaDados() {
      if (this.temaDados !== undefined) {
        this.getTurmasVincula();
      }
    },
  },
  mounted() {
    // this.preparaParaVincular();
    this.getUnidades();
  },
  methods: {
    async getTurmasVincula() {
      const response = await fetch(`${VUE_APP_CMS}api/tema/${this.temaDados}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getPerfilToken}`,
        },
      });
      const responseJSON = await response.json();
      this.turmasVincula = [];
      responseJSON.turmas.forEach((el) => {
        this.turmasVincula.push({
          cod: el.turma.cod,
          id: el.turma.id,
          name: el.turma.cod,
        });
      });
    },
    async getUnidades() {
      const response = await fetch(
        `${VUE_APP_CMS}api/unidades/getUnidadesTurmasDisponiveis`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getPerfilToken}`,
          },
        }
      );
      const responseJSON = await response.json();
      responseJSON.forEach((element, index) => {
        if (element.turmas.length > 0) {
          let obj = {
            unidade: "",
            turmas: [],
          };
          obj.unidade = element.name;
          element.turmas.forEach((el) => {
            obj.turmas.push({
              id: el.id,
              name: el.cod,
              code: el.cod,
            });
          });
          this.options.push(obj);
        }
      });
    },
    // preparaParaVincular() {
    //   this.getUnidades.forEach((element, index) => {
    //     if (element.turmas.length > 0) {
    //       let obj = {
    //         unidade: "",
    //         turmas: [],
    //       };
    //       obj.unidade = element.name;
    //       element.turmas.forEach((el) => {
    //         obj.turmas.push({
    //           id: el.id,
    //           name: el.cod,
    //           code: el.cod,
    //         });
    //       });
    //       this.options.push(obj);
    //     }
    //   });
    // },
    vincular() {
      //this.load = true;
      let objToSend = {
        tema_id: this.temaDados,
        turma_ids: [],
      };
      this.turmasVincula.forEach((element) => {
        objToSend.turma_ids.push(element.id);
      });

      $.ajax({
        type: "POST",
        url: `${this.VUE_APP_CMS}api/tema/associate`,
        data: objToSend,
        dataType: "json",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          this.load = false;
          this.toastGlobal("success", `O tema foi associado com sucesso`);

          this.hideModal("modalNameVinculaTemaExercicio");
        },
        error: (response) => {
          this.load = false;
          this.toastGlobal(
            "error",
            `Algo de errado ocorreu ao tentar associar o tema. ${response}`
          );
        },
      });

      // addTag (newTag) {
      //   const tag = {
      //     name: newTag,
      //     code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      //   }
      //   this.options.push(tag)
      //   this.turmasVincula.push(tag)
      // }
    },
  },
};
</script>

<style scoped>
.btns-vincular {
  bottom: 15px;
}

.id {
  border-radius: 25px;
  width: 25px;
  height: 25px;
}

.id span {
  font-size: 12px;
}
</style>
