<template>
  <modal
    id="modalNovasCategorias"
    name="modalNameNovasCategorias"
    :height="'auto'"
    :width="'40%'"
    :adaptive="true"
    :draggable="true"
  >
    <div
      class="containemodalNameNovasCategoriasr primeira-div-modal position-relative py-3"
    >
      <div>
        <h2 class="title-dados mb-3 text-center">
          <strong class="color-42cc7e">Nova</strong> Categoria
        </h2>
        <div class="row">
          <div class="col-md-12 mx-auto row j-c-center">
            <ValidationObserver
              ref="form"
              tag="form"
              class=""
              @submit.prevent="salvarCategorias()"
            >
              <ValidationProvider
                v-slot="{ errors, ariaMsg, classes }"
                rules="required"
                name="'Nome do Tema'"
                :bails="false"
                class="input-padding"
                tag="div"
              >
                <div class="control" :class="classes">
                  <input
                    type="text"
                    placeholder="Nome do tema"
                    class="form-control"
                    :class="classes"
                    v-model="objCategoria.name"
                  />
                  <span v-bind="ariaMsg" class="span-status-validate">{{
                    errors[0]
                  }}</span>
                </div>
              </ValidationProvider>
              <div class="btns d-flex j-c-center mt-3">
                <button
                  :class="`btn-style-default color-fff bg-d42338 `"
                  @click="hideModal('modalNameNovasCategorias')"
                >
                  cancelar
                </button>
                <button class="btn-style-default color-fff bg-0e5caf ml-3">
                  <span v-if="!load">salvar</span>
                  <div v-else class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                </button>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      load: false,
      objCategoria: {
        name: null,
      },
    };
  },
  methods: {
    hideThisModalShowParaSalvar() {
      this.$emit("chamaFormularioModal");
    },
    hideModalCategorias() {
      this.$emit("ModalCategorias");
    },
    salvarCategorias() {
      this.load = true;
      this.$refs.form.validate().then((success) => {
        if (success) {
          $.ajax({
            type: "POST",
            url: `${this.VUE_APP_CMS}api/temaGrupo`,
            data: this.objCategoria,
            dataType: "json",
            headers: {
              Authorization: this.$store.getters.getPerfilToken,
            },
            success: (response) => {
              this.hideModal("modalNameNovasCategorias");
              this.load = false;
              this.$store
                .dispatch(
                  "getCategoriasExercicios",
                  this.$store.getters.getPerfilToken
                )
                .then((resolve) => {
                  if (resolve.valid) {
                    this.hideModal("modalNameNovasCategorias");
                    this.objCategoria.name = null;
                    this.$emit("listarCategorias");
                    this.toastGlobal(
                      "success",
                      `Categorias atualizadas com sucesso`
                    );
                  } else {
                    this.load = false;
                    this.toastGlobal(
                      "erro",
                      `Algo de errado ocorreu ao tentar atualizar as categorias, recarregue a página`
                    );
                  }
                });
              this.toastGlobal("success", `Categoria cadastrada com sucesso`);
            },
            error: (response) => {
              this.load = false;
              this.toastGlobal(
                "error",
                `Algo de errado ocorreu ao tentar cadastrar categoria.`
              );
            },
          });
        } else {
          this.toastGlobal("error", resolve.msg.statusText);
        }
      });
    },
  },
};
</script>

<style></style>
