<template>
  <modal
    id="modalCategorias"
    name="modalListaCategorias"
    :width="'40%'"
    :height="'65%'"
    :adaptive="true"
    :draggable="true"
    @before-open="listarCategorias()"
  >
    <div
      id="addCategoria"
      class="p-1 d-flex j-c-center hoverStyleButton bg-0e5daf rounded-circle"
      style="width: 25px; height: 25px"
    >
      <button
        style="background-color: transparent"
        @click="adicionarCategoria()"
      >
        <i class="fas fa-plus color-fff" style="font-size: 12px"></i>
      </button>
    </div>
    <div class="modalAdicionarTema container position-relative">
      <div class="row">
        <div class="col-md-12 mx-auto position-relative">
          <h3 class="h3-modal">Categorias</h3>
          <div class="card card-body no-border" style="height: 50vh">
            <div v-if="lista.length != 0">
              <div
                class="h2 row text-left py-1 px-0"
                v-for="(item, index) in lista"
                :key="index"
              >
                <div class="col-md-8 d-flex c-center">
                  <input
                    style="font-size: 18px"
                    :id="`txb_${index}`"
                    type="text"
                    v-model="item.name"
                    disabled="true"
                  />
                </div>

                <div class="col-md-3 offset-1 d-flex justify-content-end pr-0">
                  <!-- class="editar ? '' : ' bg-42cc7e'" -->
                  <button
                    :id="`btn_${index}`"
                    class="mr-3 p-2 d-flex j-c-center w-100 bg-fedd0a"
                    @click="
                      editarDeletarCategorias(
                        `txb_${index}`,
                        `btn_icon_${index}`,
                        `btn_${index}`,
                        item.id,
                        'edit'
                      )
                    "
                  >
                    <i :id="`btn_icon_${index}`" class="fa far fa-edit"></i>
                    <!-- <i :class="editar ? 'far fa-edit' : 'fa fa-check'"></i> -->
                  </button>

                  <button
                    @click="editarDeletarCategorias(null, null, null, item.id)"
                    class="mr-3 p-2 color-fff bg-d42338 d-flex j-c-center w-100"
                  >
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="d-flex j-c-center">
                <div class="mr-3">
                  <i class="fas fa-frown color-0e5caf"></i>
                </div>
                <div class="d-flex j-c-center">
                  <span class="span-info-user color-0e5caf p-1"
                    >sem categorias cadastradas</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalNovaCategoriaExercicios @listarCategorias="listarCategorias" />
  </modal>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
import ModalNovaCategoriaExercicios from "@/components/modais/ModalNovaCategoriaExercicios";

export default {
  data() {
    return {
      selecting: "SELECIONAR",
      categorias: {
        Name: null,
      },
      lista: [],
      editar: true,
      disableBtn: true,
    };
  },
  components: {
    ModalNovaCategoriaExercicios,
  },
  props: ["type"],
  computed: {
    ...mapGetters(["getCategorias"]),
  },
  mounted() {
    this.listarCategorias();
  },
  created() {
    this.lista;
  },
  methods: {
    adicionarCategoria() {
      this.showModal("modalNameNovasCategorias");
    },
    listarCategorias() {
      this.$store
        .dispatch("getCategoriasExercicios", this.$store.getters.getPerfilToken)
        .then((resolve) => {
          this.lista = resolve.msg;
          if (resolve.valid) {
            this.toastGlobal("success", `Categorias atualizadas com sucesso`);
          } else {
            this.toastGlobal(
              "erro",
              `Algo de errado ocorreu ao tentar atualizar as categorias, recarregue a página`
            );
          }
        });
    },
    editarDeletarCategorias(val, theIcon, theBtn, id = null, mission = null) {
      var el0 = document.getElementById(val);
      var el1 = document.getElementById(theBtn)
        ? document.getElementById(theBtn)
        : false;
      var el2 = document.getElementById(theIcon);

      if (mission) {
        if (el1.classList.contains("bg-fedd0a")) {
          el0.removeAttribute("disabled");
          el1.classList.remove("bg-fedd0a");
          el1.classList.add("bg-42cc7e");
          el2.classList.add("fa-check");
          el2.classList.remove("fa-edit");
        } else {
          el0.setAttribute("disabled", this.editar);
          el1.classList.remove("bg-42cc7e");
          el1.classList.add("bg-fedd0a");
          el2.classList.add("fa-edit");
          el2.classList.remove("fa-check");
        }
      }
      if (
        (id != null && mission == null) ||
        (id != null && el1.classList.contains("bg-fedd0a"))
      ) {
        $.ajax({
          type: mission ? "PUT" : "DELETE",
          url: `${this.VUE_APP_CMS}api/temaGrupo/${id}`,
          dataType: "json",
          data: mission ? { name: `${el0.value}` } : null,
          headers: {
            Authorization: this.$store.getters.getPerfilToken,
          },
          success: (response) => {
            this.listarCategorias();
          },
          error: (response) => {
            this.toastGlobal("error", `Algo de errado ocorreu: ${response}`);
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.modalAdicionarTema {
  overflow: scroll;
}
h3,
button {
  font-size: 12px;
  font-weight: 400;
}
#addCategoria {
  margin: 15px 0 0 15px;
}
</style>
