<template>
  <div class="row shadow-sm border-default shadow-sm">
    <ModalAdicionarTema
      :dados="dados"
      @atualizaDados="atualizaDados"
    />
    <!-- <ModalNovaCategoriaExercicios /> -->
    <ModalAdicionarTemaTela2 />
    <ModalNovoExercicio
      @AvoMudarCP="AvoMudarCP"
      :editarExercicio="editarExercicio"
      @chamaModalExplicaCodigo="chamaModalExplicaCodigo('exercicio')"
    />
    <ModalConfirmacao
      :vontade="''"
      :elementoVontade="templateElementoVontade"
      :condicional="false"
      :dispararEvento="templateDispararEvento"
      :valorParaDispatch="$store.getters.getPerfilToken"
      @excluirExercicios="carregaTemasPagination"
      @confirmaDeleteTema="confirmaDeleteTema"
      @atualizaDados="atualizaDados"
    />
    <ModalEditarTema @chamaModalExplicaCodigo="chamaModalExplicaCodigo('tema')" :atualizaDados="atualizaDados" />
    <ModalExplicaCodigoGerado :type="typeModalExplica" />
    <modalVinculaTemaExercicio :temaDados="temaId" />
    <ModalCategorias />
    <div
      :class="`col-md-11 mx-auto h2 row text-center border-bottom pb-2 px-0`"
    >
      <div class="col-md-2 pl-0">
        <h2 class="f-w-700">Código</h2>
      </div>
      <div class="col-md-2">
        <h2 class="f-w-700">Tema</h2>
      </div>
      <div class="col-md-2">
        <h2 class="f-w-700">Categoria</h2>
      </div>
      <div class="col-md-1">
        <h2 class="f-w-700">Data</h2>
      </div>
    </div>
    <div
      v-for="(item, index) in dadosTemaExercicio"
      :key="index"
      :class="
        getUserDatas.profile_id == 1 ||
        item.user_id == getUserDatas.id ||
        item.profile_id == '1'
          ? 'col-md-11 py-3 px-0 mx-auto h2 text-left'
          : ''
      "
    >
      <div
        v-if="
          getUserDatas.profile_id == 1 ||
          item.user_id == getUserDatas.id ||
          item.profile_id == '1'
        "
        class="row px-3 pb-4 border-bottom"
      >
        <!-- <div v-if="verificaToShow(item.user_id)" class="row px-3 border-bottom"> -->
        <div class="col-md-2 d-flex j-c-center pl-0">
          <h3>{{ item.cod }}</h3>
        </div>
        <div class="col-md-2 d-flex j-c-center">
          <h3>{{ item.name }}</h3>
        </div>
        <div class="col-md-2 d-flex j-c-center">
          <h3>{{ item.tema_grupo ? item.tema_grupo.name : null }}</h3>
        </div>
        <div class="col-md-1 d-flex j-c-center">
          <h3>{{ item.created_at | trataDataHora }}</h3>
        </div>
        <div class="col-md-1 d-flex j-c-center">
          <span class="badge-nome-professor badge rounded-pill bg-primary">{{
            item.user_id == getUserDatas.id ? "" : item.user_name
          }}</span>
        </div>
        <div class="col-md-4 d-flex justify-content-end pr-0">
          <button
            @click="
              verificaArrow(
                `multiCollapseInQuest_${index}`,
                `btnCollapese_${index}`
              );
              exibeExercicios(index);
            "
            type="button"
            :id="`btnCollapese_${index}`"
            class="iconeCollapseTemas bg-fff"
            data-toggle="collapse"
            :data-target="`#multiCollapseInQuest_${index}`"
            aria-expanded="false"
            :aria-controls="`multiCollapseInQuest_${index}`"
          >
            <img
              src="@/assets/icones/collapseTemas.png"
              alt="collapseTemas.png"
            />
          </button>
          <div v-if="$store.getters.getPerfil == 0">
            <button
              :id="`addExercicio_${item.tema_id}`"
              class="color-fff bg-42cc7e mr-3 p-3"
              @click="emitParaMudarCP('ProfessorAdicionaExercicioComponent')"
            >
              <i class="fas fa-plus color-fff"></i>
            </button>
            <!--
          <button
            class="mr-3 p-3 bg-fedd0a"
            @click="showModal('modalNameNovoTemaInicial')"
          >
            <i class="far fa-edit"></i>
          </button>
          -->
            <button class="color-fff bg-d42338 p-3" disabled="true">
              <i class="fas fa-minus"></i>
            </button>
          </div>
          <div v-else>
            <button
              class="color-fff bg-42cc7e mr-3 p-3"
              @click="
                adicionarExercicio(item.id, item.cod, item.exercicios.length)
              "
            >
              <i class="fas fa-plus color-fff"></i>
            </button>
            <!-- v-if="item.user_id == getUserDatas.id || item.profile_id == 1" -->
            <button class="mr-3 p-3 bg-fedd0a" @click="editarTema(item)">
              <i class="far fa-edit"></i>
            </button>
            <!-- v-if="item.user_id == getUserDatas.id || item.profile_id == 1" -->
            <button
              @click="excluirTema(item.id)"
              class="color-fff bg-d42338 p-3 mr-3"
            >
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </button>
            <button
              @click="chamaModalVincularTema(item.id)"
              class="color-fff bg-0e5daf p-3"
            >
              <i class="fas fa-share-alt"></i>
            </button>
          </div>
        </div>
        <div
          class="col-md-12 px-0 collapse multi-collapse"
          :id="`multiCollapseInQuest_${index}`"
        >
          <div class="card card-body no-border">
            <div v-if="item.exercicios.length != 0">
              <div
                class="h2 row text-left py-3 px-0"
                v-for="(itemExercicio, indexExercicio) in item.exercicios"
                :key="indexExercicio"
              >
                <div class="col-md-2 d-flex c-center pl-0">
                  <!-- <h3>{{ `${item.cod}${itemExercicio.cod}` }}</h3> -->
                  <h3>{{ itemExercicio.cod }}</h3>
                </div>
                <div class="col-md-4 d-flex c-center">
                  <h3>{{ itemExercicio.name }}</h3>
                </div>
                <div class="col-md-3 d-flex c-center justify-content-between">
                  <h3>{{ item.created_at | trataDataHora }}</h3>
                </div>
                <div class="col-md-3 d-flex justify-content-end pr-0">
                  <button
                    class="mr-3 p-2 bg-fedd0a rounded-circle d-flex j-c-center"
                    @click="editExercicio(itemExercicio)"
                  >
                    <i class="far fa-edit"></i>
                  </button>

                  <button
                    @click="
                      excluirExercicio(item.exercicios[indexExercicio].id)
                    "
                    class="
                      p-2
                      color-fff
                      bg-d42338
                      rounded-circle
                      d-flex
                      j-c-center
                    "
                  >
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="d-flex j-c-center">
                <div class="mr-3">
                  <i class="fas fa-frown color-0e5caf"></i>
                </div>
                <div class="d-flex j-c-center">
                  <span class="span-info-user color-0e5caf p-1"
                    >sem exercício cadastrado</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="row px-3 pb-4 border-bottom">
        <!-- <div v-if="verificaToShow(item.user_id)" class="row px-3 border-bottom"> -->
        <div v-if="item.user_id == getUserDatas.id || item.profile_id == '1'">
          <div class="col-md-2 d-flex c-center pl-0">
            <h3>{{ item.cod }}</h3>
          </div>
          <div class="col-md-2 d-flex c-center">
            <h3>{{ item.name }}</h3>
          </div>
          <div class="col-md-2 d-flex c-center">
            <h3>{{ item.tema_grupo ? item.tema_grupo.Name : null }}</h3>
          </div>
          <div class="col-md-1 d-flex c-center justify-content-between">
            <h3>{{ item.created_at | trataDataHora }}</h3>
          </div>
          <div class="col-md-1 d-flex c-center justify-content-center">
            <span class="badge-nome-professor badge rounded-pill bg-primary"
              >>{{ item.user_name }}</span
            >
          </div>
          <div class="col-md-4 d-flex justify-content-end pr-0">
            <button
              @click="
                verificaArrow(
                  `multiCollapseInQuest_${index}`,
                  `btnCollapese_${index}`
                )
              "
              type="button"
              :id="`btnCollapese_${index}`"
              class="iconeCollapseTemas bg-fff"
              data-toggle="collapse"
              :data-target="`#multiCollapseInQuest_${index}`"
              aria-expanded="false"
              :aria-controls="`multiCollapseInQuest_${index}`"
            >
              <img
                src="@/assets/icones/collapseTemas.png"
                alt="collapseTemas.png"
              />
            </button>
            <div v-if="$store.getters.getPerfil == 0">
              <button
                :id="`addExercicio_${item.tema_id}`"
                class="color-fff bg-42cc7e mr-3 p-3"
                @click="emitParaMudarCP('ProfessorAdicionaExercicioComponent')"
              >
                <i class="fas fa-plus color-fff"></i>
              </button>
              <!--
            <button
              class="mr-3 p-3 bg-fedd0a"
              @click="showModal('modalNameNovoTemaInicial')"
            >
              <i class="far fa-edit"></i>
            </button>
            -->
              <button class="color-fff bg-d42338 p-3" disabled="true">
                <i class="fas fa-minus"></i>
              </button>
            </div>
            <div v-else>
              <button
                class="color-fff bg-42cc7e mr-3 p-3"
                @click="
                  adicionarExercicio(item.id, item.cod, item.exercicios.length)
                "
              >
                <i class="fas fa-plus color-fff"></i>
              </button>
              <!-- v-if="item.user_id == getUserDatas.id || item.profile_id == 1" -->
              <button class="mr-3 p-3 bg-fedd0a" @click="editarTema(item)">
                <i class="far fa-edit"></i>
              </button>
              <!-- v-if="item.user_id == getUserDatas.id || item.profile_id == 1" -->
              <button
                @click="excluirTema(item.id)"
                class="color-fff bg-d42338 p-3 mr-3"
              >
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </button>
              <button
                @click="chamaModalVincularTema(item.id)"
                class="color-fff bg-0e5daf p-3"
              >
                <i class="fas fa-share-alt"></i>
              </button>
            </div>
          </div>
          <div
            class="col-md-12 px-0 collapse multi-collapse"
            :id="`multiCollapseInQuest_${index}`"
          >
            <div class="card card-body no-border">
              <div v-if="item.exercicios.length != 0">
                <div
                  class="h2 row text-left py-3 px-0"
                  v-for="(itemExercicio, indexExercicio) in item.exercicios"
                  :key="indexExercicio"
                >
                  <div class="col-md-2 d-flex c-center pl-0">
                    <!-- <h3>{{ `${item.cod}${itemExercicio.cod}` }}</h3> -->
                    <h3>{{ itemExercicio.cod }}</h3>
                  </div>
                  <div class="col-md-4 d-flex c-center">
                    <h3>{{ itemExercicio.name }}</h3>
                  </div>
                  <div class="col-md-3 d-flex c-center justify-content-between">
                    <h3>{{ item.created_at | trataDataHora }}</h3>
                  </div>
                  <div class="col-md-3 d-flex justify-content-end pr-0">
                    <button
                      class="
                        mr-3
                        p-2
                        bg-fedd0a
                        rounded-circle
                        d-flex
                        j-c-center
                      "
                      @click="editExercicio(itemExercicio)"
                    >
                      <i class="far fa-edit"></i>
                    </button>

                    <button
                      @click="
                        excluirExercicio(item.exercicios[indexExercicio].id)
                      "
                      class="
                        p-2
                        color-fff
                        bg-d42338
                        rounded-circle
                        d-flex
                        j-c-center
                      "
                    >
                      <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="d-flex j-c-center">
                  <div class="mr-3">
                    <i class="fas fa-frown color-0e5caf"></i>
                  </div>
                  <div class="d-flex j-c-center">
                    <span class="span-info-user color-0e5caf p-1"
                      >sem exercício cadastrado</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div id="zero" v-if="funcao == false">
				<paginate
					v-if="pages > 1"
					:page-count="pages"
					:page-range="5"
					:margin-pages="2"
					:click-handler="(p) => this.getNews('?limit=7&page='+p)"
					prev-text="<i class='fas fa-arrow-left'></i>"
					next-text="<i class='fas fa-arrow-right'></i>"
					:container-class="'pagination'"
					:page-class="'page-item'"
				></paginate>
			</div>
			<div id="um" v-else>
				<paginate
					v-if="pages > 1"
					:page-count="pages"
					:page-range="5"
					:margin-pages="2"
					:click-handler="(p) => this.getNewsFromCategorias(categoriaSelecionada,p)"
					prev-text="<i class='fas fa-arrow-left'></i>"
					next-text="<i class='fas fa-arrow-right'></i>"
					:container-class="'pagination'"
					:page-class="'page-item'"
				></paginate>
			</div> -->
    <div
      class="
        col-md-11
        mx-auto
        h2
        d-flex
        text-left
        py-3
        px-0
        d-flex
        justify-content-between
      "
    >
      <div class="d-flex">
        <!-- v-if="$store.getters.getPerfil == 1" -->
        <button
          class="
            btn-style-default-less-2
            color-fff
            bg-0e5caf
            mr-3
            d-flex
            align-items-center
            justify-content-center
          "
          @click="openCategoria()"
        >
          categorias
          <i>
            <img
              class="m-0 ml-3 padrao-icon"
              src="@/assets/icones/iconeNovoTema.png"
              alt="iconeNovoTema.png"
            />
          </i>
        </button>
        <!-- <button
          v-else
          class="btn-style-default-less-2 color-fff bg-0e5caf mr-3 d-flex align-items-center justify-content-center"
          @click="adicionarCategoria()"
        >
          nova categoria
          <i>
            <img
              class="m-0 ml-3 padrao-icon"
              src="@/assets/icones/iconeNovoTema.png"
              alt="iconeNovoTema.png"
            />
          </i>
        </button> -->
        <button
          class="
            btn-style-default-less-2
            color-fff
            bg-0e5caf
            mr-3
            d-flex
            align-items-center
            justify-content-center
          "
          @click="adicionarTema()"
        >
          novo tema
          <i>
            <img
              class="m-0 ml-3 padrao-icon"
              src="@/assets/icones/iconeNovoTema.png"
              alt="iconeNovoTema.png"
            />
          </i>
        </button>
      </div>
      <!-- <div>
        <nav aria-label="...">
          <ul class="pagination pagination-lg">
            <li v-for="index in getPaginationExercicios" :key="index" class="page-item">
              <span class="page-link" @click="carregaTemasPagination(Number(index))">{{
                Number(index)
              }}</span>
            </li>
          </ul>
        </nav>
      </div> -->
    </div>
  </div>
</template>

<script>
import ModalAdicionarTema from "@/components/modais/ModalAdicionarTema";
import ModalAdicionarTemaTela2 from "@/components/modais/ModalAdicionarTemaTela2";
import ModalEditarTema from "@/components/modais/ModalEditarTema";
import ModalNovoExercicio from "@/components/modais/ModalNovoExercicio";
// import ModalNovaCategoriaExercicios from "@/components/modais/ModalNovaCategoriaExercicios";
import Loader from "@/components/Loader";
import ModalConfirmacao from "@/components/modais/ModalConfirmacao";
import ModalExplicaCodigoGerado from "@/components/modais/ModalExplicaCodigoGerado";
import modalVinculaTemaExercicio from "@/components/modais/ModalVincularTemasExercicios";
import ModalCategorias from "@/components/modais/ModalCategorias";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      templateElementoVontade: "exercício",
      templateDispararEvento: "excluirExercicio",
      dados: null,
      typeModalExplica: null,
      editarExercicio: {},
      temaId: null,
      idTemaToDelete: null,
      temas: [],
      listaTemasProfessore: [],
      // dataExercicios: this.$store.getters.getTemasExercicios,
    };
  },
  computed: {
    ...mapGetters([
      "getTemasExercicios",
      "getPaginationExercicios",
      "getCategorias",
      "getUserDatas",
    ]),
  },
  components: {
    ModalAdicionarTema,
    ModalAdicionarTemaTela2,
    ModalNovoExercicio,
    ModalConfirmacao,
    ModalEditarTema,
    // ModalNovaCategoriaExercicios,
    ModalExplicaCodigoGerado,
    modalVinculaTemaExercicio,
    ModalCategorias,
  },
  props: ["dadosTemaExercicio", "mt"],
  watch: {
    getCategorias: function () {
      this.temas = this.filtrosDePesquisaDados("", "temas");
    },
  },
  created() {
    this.exibirTemasExerciociosProfessores();
  },
  methods: {
    exibirTemasExerciociosProfessores() {
      this.listaTemasProfessore = this.getTemasExercicios.filter(
        (arr) => arr.user_id == this.getUserDatas.id || arr.profile_id == "1"
      );
    },
    AvoMudarCP() {
      this.$emit("AvoMudarCP");
    },
    openCategoria() {
      this.showModal("modalListaCategorias");
    },
    adicionarExercicio(idTema, codExercicio, Exercicioslength) {
      this.$store.commit("SET_TEMA_ID", idTema);
      this.$store.commit("SET_COD_EXERCICIO", codExercicio);
      this.$store.commit("SET_COD_EXERCICIO_LENGTH", Exercicioslength);
      this.showModal("modalNameNovoExercicio");
      this.editarExercicio = {};
      emitParaMudarCP("ProfessorAdicionaExercicioComponent");
    },
    excluirExercicio(val) {
      this.showModal("modalNameConfirmacao");
      this.$store.commit("SET_EXERCICIO_ID", val);
      this.templateElementoVontade = "excluir exercício";
      this.templateDispararEvento = "excluirExercicio";
    },
    exibeExercicios(index) {
      document
        .querySelector(`#multiCollapseInQuest_${index}`)
        .classList.toggle("collapse");
    },
    adicionarTema() {
      this.showModal("modalNameNovoTemaInicial");
    },
    editarTema(item) {
      this.$store.commit("SET_DADOS_TEMA", item);
      this.showModal("modalNameEditarTemaInicial");
    },
    adicionarCategoria() {
      this.showModal("modalNameNovasCategorias");
    },
    editExercicio(item) {
      this.editarExercicio = item;
      this.showModal("modalNameNovoExercicio");
    },
    carregaTemasPagination(val) {
      this.$store
        .dispatch("getTemas", {
          token: this.$store.getters.getPerfilToken,
        })
        .then((resolve) => {
          if (resolve.valid) {
            this.showLoader = false;
            this.toastGlobal(
              "success",
              "Lista de exercícios atualizada com sucesso."
            );

            this.atualizaDados();
          } else {
            this.toastGlobal(
              "error",
              `Algo de errado ocorreu, ${resolve.msg.responseJSON.msg}`
            );
          }
        });
    },
    chamaModalExplicaCodigo(val) {
      this.typeModalExplica = val;
      this.showModal("modalNameNovoExplicaCodigoGerado");
    },
    chamaModalVincularTema(id) {
      this.temaId = id;
      this.showModal("modalNameVinculaTemaExercicio");
    },
    excluirTema(temaId) {
      this.idTemaToDelete = temaId;
      this.templateElementoVontade = "excluir tema";
      this.showModal("modalNameConfirmacao");
    },
    confirmaDeleteTema() {
      let objToDelete = {
        id: this.idTemaToDelete,
        token: this.$store.getters.getPerfilToken,
      };
      this.$store.dispatch("excluirTema", objToDelete).then((resolve) => {
        if (resolve.valid) {
          this.temas = this.filtrosDePesquisaDados("", "temas");
          this.toastGlobal("success", "Lista de temas atualizada com sucesso.");
          setTimeout(() => {
            this.atualizaDados();
          }, 500);
        } else {
          this.toastGlobal(
            "error",
            `Algo de errado ocorreu, ${resolve.msg.responseJson.msg}`
          );
        }
      });
    },
    atualizaDados() {
      this.$emit("atualizaDados");
      //location.reload();
    },
    // verificaCategoria(verificaIdCategoria){
    //   this.getCategorias.forEach(el => {
    //     if (el.tema_categoria_id == verificaIdCategoria) {
    //       return el.Name
    //     }
    //   });
    // }
    verificaToShow(val) {
      return val == this.getUserDatas ? true : false;
    },
  },
  mounted() {
    setTimeout(() => {
      this.temas = this.filtrosDePesquisaDados("", "temas");
    }, 1000);
  },
};
</script>

<style scoped>
.badge-nome-professor {
  font-size: 10px;
  color: #fff;
  width: 120px;
  height: 20px;
  margin-left: 38px;
  padding-top: 12.5%;
}

h2 {
  font-size: 14px;
}

h3,
button {
  font-size: 12px;
  font-weight: 400;
}

button img {
  width: 25px;
  margin: 0 20px;
}
</style>
