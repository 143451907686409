<template>
  <modal
    id="modalEditarTemaInicial"
    name="modalNameEditarTemaInicial"
    :height="'auto'"
    :width="'40%'"
    :adaptive="true"
    :draggable="true"
  >
    <div class="modalAdicionarTema container pt-3">
      <div class="row">
        <div class="col-md-10 mx-auto position-relative">
          <h3 class="h3-modal">
            <strong class="color-fedd0a">Editar</strong> Tema:
          </h3>
          <ValidationObserver
            ref="form"
            tag="form"
            class="py-3 text-left"
            @submit.prevent="editarTema()"
          >
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="required"
              name="'Código do Exercício'"
              :bails="false"
              class=""
              tag="div"
            >
              <div @click="chamaModalExplicaCodigo()">
                <label for="">Código do Tema</label>
                <input disabled type="text" v-model="$store.getters.getDadosTemas.cod" />
                <span v-bind="ariaMsg" class="span-status-validate">{{
                  errors[0]
                }}</span>
              </div>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="required"
              name="'Nome do Exercício'"
              :bails="false"
              class=""
              tag="div"
            >
              <label for="" class="mt-4">Título do Tema</label>
              <input type="text" v-model="$store.getters.getDadosTemas.name" />
              <span v-bind="ariaMsg" class="span-status-validate">{{
                errors[0]
              }}</span>
            </ValidationProvider>

            <div
              class="btns d-flex align-items-center justify-content-center mt-3"
            >
              <button
                class="btn-style-default color-fff bg-d42338"
                @click.prevent="hideModal('modalNameEditarTemaInicial')"
              >
                cancelar
              </button>
              <button
                class="btn-style-default color-fff bg-0e5caf ml-3"
                type="submit"
                :disabled="show"
              >
                {{ statusEdicao }}
                <div v-if="show" class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </button>
              <!--<button class="btn-style-default color-fff bg-0e5caf ml-3" @click="chamaProximaModalLinkaTema()">próximo</button>-->
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      selecting: "SELECIONAR",
      postObj: {
        cod: "",
        name: "",
      },
      statusEdicao: "Alterar",
      show: false,
    };
  },
  props: ["type"],
  computed: {},
  methods: {
    chamaModalExplicaCodigo() {
      this.$emit("chamaModalExplicaCodigo", "tema");
    },
    changeSelect(val) {
      this.selecting = val;
    },
    chamaProximaModalLinkaTema(val) {
      this.$emit("chamadaProximaModalLinkaTema", val);
    },
    editarTema() {
      this.show = true;
      this.statusEdicao = null;
      this.$refs.form.validate().then((success) => {
        this.postObj = {
          cod: this.$store.getters.getDadosTemas.cod,
          name: this.$store.getters.getDadosTemas.name,
        };
        if (success) {
          $.ajax({
            type: "PUT",
            url: `${this.VUE_APP_CMS}api/tema/${this.$store.getters.getDadosTemas.id}`,
            data: this.postObj,
            dataType: "json",
            headers: {
              Authorization: this.$store.getters.getPerfilToken,
              // "Content-Type": "application/x-www-form-urlencodd"
            },
            success: (response) => {
              this.toastGlobal(
                "success",
                `Tema: '${this.postObj.cod} ${this.postObj.name}'' foi editado com sucesso`
              );

              this.show = false;
              this.statusEdicao = "Alterar";
              this.hideModal("modalNameEditarTemaInicial");
              this.$emit("atualizaDados");
            },
            error: (response) => {
              this.show = false;
              this.statusEdicao = "Alterar";
              this.toastGlobal("error", `Erro: ${response.responseJSON}`);
            },
          });
        } else {
          this.toastGlobal("success", `Preencha o formulário devidamente`);
        }
      });
    },
    resetFormulario() {
      (this.selecting = "SELECIONAR"),
        (this.postObj.cod = null),
        (this.postObj.name = null);
    },
  },
};
</script>

<style scoped></style>
