<template>
  <modal
    id="modalNovoTemaInicial"
    name="modalNameNovoTemaInicial"
    :width="'40%'"
    :height="'auto'"
    :adaptive="true"
    :draggable="true"
    @opened="callInFirst()"
  >
    <div class="modalAdicionarTema container py-3">
      <div class="row">
        <div class="col-md-10 mx-auto position-relative">
          <h3 class="h3-modal">
            <strong class="color-42cc7e">Novo</strong> Tema:
          </h3>
          <ValidationObserver
            ref="form"
            tag="form"
            class="mt-2 text-left"
            @submit.prevent="adicionarTema()"
          >
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="required"
              name="'Nome do Exercício'"
              :bails="false"
              class=""
              tag="div"
            >
              <label for="" class="mt-4">Título do Tema</label>
              <input type="text" v-model="postObj.name" />
              <span v-bind="ariaMsg" class="span-status-validate">{{
                errors[0]
              }}</span>
            </ValidationProvider>

            <label for="" class="mt-4">Categoria do Exercício</label> <br />
            <ValidationProvider
              rules="excluded:0|required"
              name="'Categoria do Exercício'"
              v-slot="{ errors }"
            >
              <select name="" id="" v-model="postObj.tema_grupo_id">
                <option class="text-uppercase" value="0" selected>
                  Selecionar
                </option>
                <option
                  v-for="(item, index) in getCategorias"
                  :value="item.id"
                  :key="index"
                  class="text-uppercase"
                  selected
                >
                  {{ item.name }}
                </option>
              </select>
              <span class="span-status-validate">{{ errors[0] }}</span>
            </ValidationProvider>
            <div
              class="btns d-flex align-items-center justify-content-center mt-3"
            >
              <button
                class="btn-style-default color-fff bg-d42338"
                @click="cancelarTema()"
                :disabled="load"
              >
                cancelar
              </button>
              <button
                class="btn-style-default color-fff bg-0e5caf ml-3"
                type="submit"
              >
                <span v-if="!load">salvar</span>
                <div v-else class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </button>
              <!--<button class="btn-style-default color-fff bg-0e5caf ml-3" @click="chamaProximaModalLinkaTema()">próximo</button>-->
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      selecting: "SELECIONAR",
      postObj: {
        cod: "",
        name: "",
        tema_grupo_id: "",
      },
      categorias: [],
      reCall: true,
      lastPageTemas: 1,
      lastTema: [],
      load: false,
    };
  },
  props: ["type"],
  computed: {
    ...mapGetters(["getCategorias"]),
  },
  mounted() {
    this.callInFirst();
  },
  methods: {
    changeSelect(val) {
      this.selecting = val;
    },
    chamaProximaModalLinkaTema(val) {
      this.$emit("chamadaProximaModalLinkaTema", val);
    },
    adicionarTema() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.load = true;
          $.ajax({
            type: "POST",
            url: `${this.VUE_APP_CMS}api/tema`,
            data: this.postObj,
            dataType: "json",
            headers: {
              Authorization: this.$store.getters.getPerfilToken,
              // "Content-Type": "application/x-www-form-urlencodd"
            },
            success: (response) => {
              this.load = false;
              this.callInFirst();
              this.$store
                .dispatch("getTemas", {
                  token: this.$store.getters.getPerfilToken,
                  page: 1,
                })
                .then((resolve) => {
                  if (resolve.valid) {
                    this.showLoader = false;
                    this.$emit("atualizaDados");
                    this.resetFormulario();
                    // this.toastGlobal(
                    //   "success",
                    //   "Lista de temas atualizada com sucesso"
                    // );

                    //location.reload();
                  } else {
                    this.load = false;
                    // this.toastGlobal(
                    //   "error",
                    //   `Algo de errado ocorreu, ${resolve.msg.responseJSON.msg}`
                    // );
                  }
                });

              this.toastGlobal(
                "success",
                `Tema: '${this.postObj.cod} ${this.postObj.name} foi criado com sucesso' `
              );

              this.hideModal("modalNameNovoTemaInicial");
            },
            error: (response) => {
              this.load = false;
              this.toastGlobal("error", `Erro: ${response.responseJSON}`);
            },
          });
        } else {
          this.toastGlobal("error", "Preencha o formulário devidamente.");
        }
      });
    },

    resetFormulario() {
      this.selecting = "SELECIONAR";
      this.postObj.cod = null;
      this.postObj.name = null;
      this.postObj.tema_grupo_id = null;
    },

    cancelarTema() {
      this.resetFormulario();
      this.hideModal("modalNameNovoTemaInicial");
    },

    getCategoriasNow() {
      $.ajax({
        type: "GET",
        url: `${this.VUE_APP_CMS}api/temaGrupo`,
        data: null,
        dataType: "json",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          this.categorias = response;
          this.$store.commit("SET_CATEGORIAS", response);

          this.toastGlobal("success", "Categorias carregadas com sucesso.");
        },
        error: (response) => {
          this.toastGlobal(
            "error",
            "Algo de errado ocorreu ao tentar cadastrar categoria"
          );
        },
      });
    },
    geraCodigoDoTema() {
      $.ajax({
        type: "GET",
        url: `${this.VUE_APP_CMS}api/tema`,
        data: null,
        dataType: "json",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          if (this.reCall) {
            this.lastPageTemas = response.last_page;
            this.geraCodigoDoTema();
            this.lastTema =
              response.length > 1 ? response[response.length - 1] : 0;
            this.geradorDeCod();
          }
          this.reCall = false;
        },
        error: (response) => {
          this.toastGlobal(
            "error",
            "Algo de errado ocorreu ao tentar cadastrar categoria"
          );
        },
      });
    },
    chamaModalExplicaCodigo() {
      this.$emit("chamaModalExplicaCodigo", "tema");
    },
    geradorDeCod() {
      let dadosUser = this.$store.getters.getUserDatas;
      let val1 =
        dadosUser.profile_id < 10
          ? `00${dadosUser.profile_id}`
          : dadosUser.profile_id < 100
          ? `0${dadosUser.profile_id}`
          : "";
      let val2 =
        dadosUser.id + 1 < 10
          ? `00${dadosUser.id + 1}`
          : dadosUser.id + 1 < 100
          ? `0${dadosUser.id + 1}`
          : "";
      let val3 =
        this.lastTema.id + 1 < 10
          ? `00${this.lastTema.id + 1}`
          : this.lastTema.id + 1 < 100
          ? `0${this.lastTema.id + 1}`
          : "";
      let val4 = dadosUser.cpf ? dadosUser.cpf.substr(9, 10) : "00";
      this.postObj.cod = `${val1}${val2}${val3}0${val4}`;
      return this.postObj.cod;
    },

    callInFirst() {
      this.getCategoriasNow();
      this.geraCodigoDoTema();
      this.reCall = true;
    },
  },
};
</script>

<style scoped></style>
