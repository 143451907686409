import { render, staticRenderFns } from "./ModalCategorias.vue?vue&type=template&id=ceb8b98a&scoped=true&"
import script from "./ModalCategorias.vue?vue&type=script&lang=js&"
export * from "./ModalCategorias.vue?vue&type=script&lang=js&"
import style0 from "./ModalCategorias.vue?vue&type=style&index=0&id=ceb8b98a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ceb8b98a",
  null
  
)

export default component.exports