<template>
<modal id="modalNovoTemaTela2" name="modalNameNovoTemaTela2" :height="'70%'" :width="'70%'" :adaptive="true" :draggable="true">
    <div class="modalAdicionarTema container mt-5">
        <div class="row">
            <div class="col-md-10 mx-auto position-relative">
                <h3>Selecione:</h3>
                <div class="row mt-4">
                    <div class="col-md-4 ht">
                        <h3 class="mb-3">Unidades/Instituição</h3>
                        <div class="content scrollable p-3">
                            <div class="d-flex c-center" v-for="(item, index) in 15" :key="index">
                                <div class="Ochecker d-flex align-items-center justify-content-center" @click="checking(`idCheckerUnidadeInstituicao_${index}`)">
                                    <img :id="`idCheckerUnidadeInstituicao_${index}`" class="checker-img d-block" src="@/assets/icones/Check.png" alt="Check.png">
                                </div>
                                <div class="ml-2">
                                    <p>001 - Escola Estadual Dom Bosco</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 ht">
                        <h3 class="mb-3">Turmas</h3>
                        <div class="content scrollable p-3">
                            <div class="d-flex c-center" v-for="(item, index) in 5" :key="index">
                                <div class="Ochecker d-flex align-items-center justify-content-center" @click="checking(`idCheckerTurmas_${index}`)">
                                    <img :id="`idCheckerTurmas_${index}`" class="checker-img d-block" src="@/assets/icones/Check.png" alt="Check.png">
                                </div>
                                <div class="ml-2">
                                    <p>001 - 5 Série A</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 ht">
                        <h3 class="mb-3">Alunos</h3>
                        <div class="content scrollable p-3">
                            <div class="d-flex c-center" v-for="(item, index) in 47" :key="index">
                                <div class="Ochecker d-flex align-items-center justify-content-center" @click="checking(`idCheckerAlunos_${index}`)">
                                    <img :id="`idCheckerAlunos_${index}`" class="checker-img d-block" src="@/assets/icones/Check.png" alt="Check.png">
                                </div>
                                <div class="ml-2">
                                    <p>0000001 - Aluno Skaki</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btns d-flex align-items-center justify-content-center position-absolute">
                <button class="btn-style-default color-fff bg-d42338" @click="hideModal('modalNameNovoTemaTela2')">cancelar</button>
                <button class="btn-style-default color-fff bg-0e5caf ml-3" @click="emitParaMudarCP('alunoMeusTemasExercicioComponent')">próximo</button>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
export default {
    data() {
        return {
            selecting: 'SELECIONAR'
        }
    },
    methods: {
        changeSelect(val) {
            this.selecting = val
        },
        checking(val) {
            if ($(`#${val}`).hasClass('d-block')) {
                $(`#${val}`).removeClass('d-block').addClass('d-none')
            } else {
                $(`#${val}`).removeClass('d-none').addClass('d-block')
            }
        }
    },
}
</script>

<style scoped>
h3 {
    font-size: 14px;
}

.btns {
    width: 100%;
    bottom: 15px;
}

.content {
    border: 1px solid #707070;
    border-radius: 5px;
    height: 250px;
}

.content>div {
    height: 35px;
    width: 100%;
}

p {
    font-size: 12px;
}

.option {
    height: 35px;
}

.ht {
    height: 250px;
}

.Ochecker {
    height: 20px;
    width: 20px;
    border-width: 1px;
}
</style>
