<template>
  <modal
    id="modalNovoExercicio"
    name="modalNameNovoExercicio"
    :height="'auto'"
    :width="'50%'"
    :draggable="true"
    @opened="verificaEdicao"
  >
    <div class="position-relative container mt-3 primeira-div-modal" style="height: 100%">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <div class="text-center">
            <h3 class="h3-modal">
              <span :class="`${editarExercicio.id ? 'color-fedd0a' : 'color-42cc7e'}`">{{
                editarExercicio.id ? "Editar" : "Novo"
              }}</span>
              Exercício:
            </h3>
          </div>
          <ValidationObserver
            ref="form"
            tag="form"
            class="mt-5"
            @submit.prevent="toPaginaMontaTabuleiroExercicio()"
          >
            <div class="row">
              <div class="col-md-6 text-left pr-5">
                <label for="" class="mb-3">Código do Exercício</label>                
                <div @click="chamaModalExplicaCodigo()">
                  <input
                    type="text"
                    :value="
                      editarExercicio.cod
                        ? editarExercicio.cod
                        : $store.getters.getCodigoGeradoNovoExercicio
                    "
                    disabled
                  />
                </div>

                <ValidationProvider
                  v-slot="{ errors, ariaMsg, classes }"
                  rules="required"
                  name="temaNome"
                  :bails="false"
                  class="input-padding"
                  tag="div"
                >
                  <label for="" class="my-2">Nome do exercício</label>
                  <input
                    id="exercicioName"
                    required
                    class="form-control"
                    :class="classes"
                    type="text"
                    :value="editarExercicio.name ? editarExercicio.name : `Exercício ${Number($store.getters.getCodigoGeradoNovoExercicio.split('-')[1])+1}`"
                    placeholder="Aprendendo o Básico de Xadrex: Parte 02"
                  />
                </ValidationProvider>
                <!--
                <label for="" class="my-3">Aprendendo o Básico de Xadrex: Parte 02</label>
                <input type="text" disabled />
                -->

                <div class="row mt-3">
                  <div class="col-md-5 pr-0 d-flex c-center">
                    <label for="">Peças do aluno:</label>
                  </div>
                  <div class="col-md-7 d-flex justify-content-between radios">
                    <div class="d-flex align-items-center justify-content-center">
                      <label for="chosePartBlack"
                        ><img
                          src="@/assets/icones/iconePeaoPreto.png"
                          alt="iconePeaoPreto.png"
                      /></label>
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                      <input
                        type="radio"
                        name="chosePart"
                        id="chosePartBlack"
                        value="black"
                        v-model="objToCreate.cor"
                      />
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                      <label for="chosePartWhite"
                        ><img
                          src="@/assets/icones/iconePeaoBranco.png"
                          alt="iconePeaoBranco.png"
                      /></label>
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                      <input
                        type="radio"
                        name="chosePart"
                        id="chosePartWhite"
                        value="white"
                        v-model="objToCreate.cor"
                        checked
                      />
                    </div>
                  </div> 
                </div>
                  <div id="gameType" class="row mt-3">
                    <div class="col-md-5 pr-0 d-flex c-center">
                      <label for="">Tipo de Exercício</label>
                    </div>
                    <div class="col-md-7 d-flex justify-content-between radios">
                      <div class="d-flex align-items-center justify-content-center">
                        <label for="choseStockFish"><i class="fas fa-fish pointer"></i></label>
                      </div>
                      <div class="d-flex align-items-center justify-content-center">
                        <input
                          type="radio"
                          name="choseStockFish"
                          id="choseStockFish"
                          value="2"
                          v-model="objToCreate.exercicio_categoria_id"
                        />
                      </div>
                      <div class="d-flex align-items-center justify-content-center">
                        <label for="choseStockPGN"><i class="fas fa-table pointer"></i></label>
                      </div>
                      <div class="d-flex align-items-center justify-content-center">
                        <input
                          type="radio"
                          name="choseStockPGN"
                          id="choseStockPGN"
                          value="1"
                          v-model="objToCreate.exercicio_categoria_id"
                          checked
                        />
                      </div>
                    </div> 
                  </div>
              </div>
              <div class="col-md-6 text-left">

                <label for="" class="my-2">Dicas</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Dicas do exercício..."
                  v-model="objToCreate.dica"
                ></textarea>
              </div>
              <div
                class="py-3 d-flex align-items-center justify-content-center col-md-12"
              >
                <button
                  class="btn-style-default color-fff bg-d42338"
                  @click.prevent="hideModal('modalNameNovoExercicio')"
                >
                  cancelar
                </button>
                <button class="btn-style-default color-fff bg-0e5caf ml-3">
                  próximo
                </button>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import $ from "jquery";
import {mapGetters} from 'vuex'
export default {
  data() {
    return {
      habilitaCampo: false,
      transformVAL: "Selecionar",
      objToCreate: {
        cor: "white",
        cod: null,
        name: null,
        exercicio_categoria_id: 1,
        PGN: this.exercicio_categoria_id == 1 ? [] : "vazio",
        step: 1,
        dica: null,
      },
    };
  },
  props: ["inPage", "editarExercicio"],
  watch: {
    habilitaCampo() {
      if (this.habilitaCampo) {
        this.habilitaCampo = true;
      }
    },
  },
  mounted() {},
  methods: {
    changeSelect(val) {
      switch (val) {
        case 1:
          this.transformVAL = "PGN";
          break;
        case 2:
          this.transformVAL = "Contra StockFish";
          break;
        case 3:
          this.transformVAL = "Testar StockFish";
          break;

        default:
          break;
      }
      this.objToCreate.exercicio_categoria_id = val;
    },
    emitParaMudarCP(val = null, exercicio_categoria_id = null) {
      this.$emit("AvoMudarCP", val, exercicio_categoria_id);
    },
    emiteDadosParaPagina() {
      this.$emit("recebeDadosDaModal");
    },
    toPaginaMontaTabuleiroExercicio() {
      this.$store.commit("SET_STATUS_GRAVACAO_EXERCICIO", false);
      this.$store.dispatch("resetCamposStore");
      this.$store.commit("SET_MEU_TABULEIRO", []);
      if (this.editarExercicio) {
        this.$store.commit("SET_EDITA_EXERCICIO", this.editarExercicio);
      } else {
        this.$store.commit("SET_EDITA_EXERCICIO", {});
      }
        this.inPage
          ? this.emiteDadosParaPagina()
          : this.emitParaMudarCP(this.habilitaCampo);
        this.$store.commit("SET_TYPE_EXERCICIO", this.objToCreate.exercicio_categoria_id);
        this.objToCreate.cod = this.$store.getters.getCodigoGeradoNovoExercicio;
        this.objToCreate.name = document.getElementById('exercicioName').value
        this.$store.commit("SET_DADOS_EXERCICIOS", this.objToCreate);
     
    },
    verificaEdicao() {
      if (this.editarExercicio.id) {
        let valor = this.editarExercicio;
        this.changeSelect(valor.exercicio_categoria_id);
        this.objToCreate = {
          cor: valor.cor,
          name: valor.name,
          exercicio_categoria_id: valor.exercicio_categoria_id,
          PGN: valor.PGN,
          step: valor.step,
          dica: valor.dica,
        };
      }
    },
    chamaModalExplicaCodigo(){
      this.$emit('chamaModalExplicaCodigo')
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  background-color: red;
  width: 15px;
}

.radios {
  width: 100%;
}

.radios img {
  width: 15px;
}

textarea {
  width: 100%;
  height: 225px;
}

.btns {
  bottom: 20px;
}

.radios img:hover,
input[type="radio"]:hover {
  cursor: pointer;
}

label {
  margin: 0;
}

#gameType i{
  font-size: 20px;
}
</style>
